@import "../../variables";

.button {
  transition: .3s;
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer */
  -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
  -webkit-user-select: none; /* Chrome, Safari, and Opera */
  -webkit-touch-callout: none; /* Disable Android and iOS callouts*/

  cursor: pointer;
  border-radius: 22px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  background-color: $download-color;
  border: none;
  color: white;
  height: 44px;
  max-height: 44px;
  width: 240px;
  font-weight: 600;

  .icon {
    width: 24px;
    margin-right: 8px;
  }
}