@import "../../variables";

.box {
  padding: 100px $container-padding-right 100px $container-padding-left;
}

.box-gray {
  background-color: $gray-color;
}

.box-blue {
  background-color: $blue-color;
}
