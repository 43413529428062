@import "../../variables.scss";

.navItem {
    padding: 0 15.5px;

    a {
        text-decoration: none;
    }
}

.navBar {
    padding: 0;
    margin: 0;
}

.header .navItem > .navBar__navLink {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #999999;
}

.navItem > .navBar__navLink.navBar__navLink_theme_download {
    color: $download-color;
}

.menuDivider {
    border: 1px solid $separator-color;
}
