@import "../../variables";

.headline {

  .title {
    font-size: 48px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    color: #233646;

    margin-bottom: 24px;
  }

  p {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: #788691;

    margin-bottom: 52px;
  }

}

.benefits-headline {
  text-align: center;

  .benefits__header {
    max-width: 800px;
    margin-bottom: 24px;
  }

  .benefits__content {
    max-width: 460px;
  }

}

.benefits-list {

  .button-blue {
    background-color: $blue-color;
  }
}

.features {

  .features__header {
    max-width: 800px;
    margin-bottom: 80px;
  }

  .features__content {
    padding-bottom: 80px;
  }

}

.info {
  .contributor {
    max-width: 460px;

    .contributor__header {
      padding-bottom: 24px;
    }
  }
}

.footer {

  .footer__button {
    padding-bottom: 40px;
  }

  .footer__sponsored {
    color: white;
    font-size: 0.9rem;
  }

  .footer__url {
    color: white;
    font-size: 0.9rem;
  }

  .footer__rights {
    color: white;
    font-size: 0.7rem;
  }
}

