//custom variables
$download-color: #5c80bc;
$paragraph-color: #788691;
$separator-color: #e1e1e1;
$blue-color: #233646;
$gray-color: #eeeeee;

//$container-padding-left: 64px;
//$container-padding-right: 64px;
$container-padding-left: 4.4vw;
$container-padding-right: 4.4vw;
