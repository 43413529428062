@import "../../variables";

.list {
  list-style-type: none;
  padding-inline-start: 0;
  margin-bottom: 0;

  li {
    border-bottom: 1px solid $separator-color;
    padding: 100px 0;
  }

  li:last-child {
    border: none;
  }
}