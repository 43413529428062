@import 'variables.scss';

body {
  margin: 0;
  /**
    Default system font stack, so fonts are loaded faster
   */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;

  //https://usabilitypost.com/2012/11/05/stop-fixing-font-smoothing/
  //-webkit-font-smoothing: antialiased;
  //-moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 16px;
}

p {
  color: $paragraph-color;
  font-size: 1.125rem;
}

//bootstrap theme overwrites
@import "bootstrap-overrides";
@import '../../node_modules/bootstrap/scss/bootstrap';